import React, { useState } from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import CustomButton from 'components/common/custom-button';
import { Grid } from '@material-ui/core';
import { AddIP, EditLabel, IPContainer } from 'components/manage-vm/settings-panel/components';
import { useMutation, useQuery } from 'react-query';
import { get } from 'lodash';
import moment from 'moment';
import Loading from 'components/common/loading';
import { disableStyles, isInProgress } from 'components/manage-vm/utils';
import hooks from 'hooks';
import { DELETE_SERVER_IP } from 'components/manage-vm/queries';
import { checkDeletedItems } from 'components/manage-vm/utils';
import { Trans, useTranslation } from 'react-i18next';
import { features, extraFeatures, extraWithMegaraidFeatures } from './util';
import { Icon } from 'semantic-ui-react';
import { Feature, Extra } from 'components/bm-deployment/server-size-card/ServerSizeCard.style';
import { filterDataKey2, filterMemoryType, filterCount2 } from 'components/bm-deployment/server-size-card/utils';
import ConditionalFragment from 'components/conditional-fragment';
import { useSelector } from 'react-redux';
import { userRights } from 'store/selectors/user-details';
import { H6 } from 'components/reusable-css';
import Heading from 'components/Heading/Heading';
import { createFeatures } from 'components/vm-deployment/constants';
import { Highlight } from 'components/common';

const InfoManager = (props) => {
  const { t } = useTranslation();
  const { data, serverSizeData, managerVmRefetch, allTags, isTags, statusOfServer, managerVmIsFetching, type } = props;
  const { refreshTime, mutationProps } = hooks();
  const [isFetching, setIsFetching] = useState(false);
  const [deletedItem, setDeletedItem] = useState('');
  const [deletedIps, setDeletedIps] = useState([]);
  const [openTag, setOpenTag] = useState(false);
  const handleClose = () => setOpenTag(false);
  const handleOpen = () => setOpenTag(true);
  let checkDisabledItem = (ip) => checkDeletedItems(deletedIps, ip);
  const networkType = `${type}_ipv4[0]`;
  const {
    data: networkData,
    isLoading: networkLoading,
    refetch: ipRefetch,
  } = useQuery(`/${type || 'vs'}/instance/${data.id}/ipv4`, {
    ...(isFetching ? { refetchInterval: refreshTime } : {}),
    onSuccess: (res) => {
      setDeletedIps([]);
      setDeletedItem('');
      setIsFetching(isInProgress(get(res, networkType, {})));
    },
  });
  const { data: sizeData, isLoading: sizeDataLoading } = useQuery(`/vs/sizes/${data?.size}`);
  let network = get(networkData, networkType, {});
  const { mutate: deleteIp, isLoading: deleteIpLoad } = useMutation(DELETE_SERVER_IP, {
    ...mutationProps('', {
      onSuccess: async () => {
        ipRefetch();
        setDeletedItem('');
        managerVmRefetch();
        // await ipRefetch();
      },
      onError: () => {
        setDeletedItem('');
      },
    }),
  });
  const handleDelete = async (ip) => {
    setDeletedItem(ip);
    await deleteIp(
      { id: data.id, ip: ip },
      {
        onSuccess: () => {
          setDeletedIps([...deletedIps, ip]);
        },
      },
    );
  };
  const checkIpQuantity = (quantity) => {
    return get(network, 'networks', []).some((network) =>
      network.eth0?.some(
        (eth) => eth.ipv4?.filter((item) => !deletedIps.some((obj) => obj === item.ip)).length === quantity,
      ),
    );
  };

  const rights = useSelector(userRights);

  const serverVmSizes = sizeData?.vs_sizes.length > 0 ? sizeData?.vs_sizes[0] : {};

  if (networkLoading || sizeDataLoading) {
    return <Loading />;
  }
  return (
    <Styles.InfoDetail>
      <div className="first">
        <H6 className="mb-4 ml-1">{type === 'bm' ? t('bm/instance.string38') : t('vs/instance.string113')}</H6>
        {type === 'vs' && (
          <>
            <Grid container>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <Heading small name={'vs/instance.string205'} />
              </Grid>
            </Grid>
            <ConditionalFragment condition={serverVmSizes && Object.keys(serverVmSizes).length}>
              <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} />
                <Styles.IPText style={{ margin: '0.5rem 1.6em' }}>
                  <Styles.ServerSizeCardSC>
                    <Styles.FlexCon>
                      <Styles.Name>{data.size.toUpperCase()}</Styles.Name>
                    </Styles.FlexCon>
                    <Styles.Features>
                      {createFeatures.map(
                        (feature) =>
                          ((feature.lanRef == 'ddos_protection' && serverVmSizes[feature.dataKey] == 1) ||
                            feature.lanRef != 'ddos_protection') && (
                            <Styles.Feature style={{ alignItems: 'center' }} key={feature.id}>
                              <Icon name="check" />
                              <Trans
                                i18nKey={
                                  feature.lanRef == 'cpu' && serverVmSizes.vcpugen == 'xeongold'
                                    ? serverVmSizes[feature.dataKey] > 1
                                      ? 'vs/instance/create.step1_string16'
                                      : 'vs/instance/create.step1_string15'
                                    : feature.lanRef == 'cpu' && serverVmSizes.vcpugen == 'xeone5v4'
                                    ? serverVmSizes[feature.dataKey] > 1
                                      ? 'vs/instance/create.step1_string18'
                                      : 'vs/instance/create.step1_string17'
                                    : serverVmSizes[feature.dataKey] > 1
                                    ? feature.lang_plural
                                    : feature.lang
                                }
                                values={{ count: serverVmSizes[feature.dataKey] }}
                                components={{ highlight: <Highlight /> }}
                              />
                            </Styles.Feature>
                          ),
                      )}
                    </Styles.Features>
                  </Styles.ServerSizeCardSC>
                </Styles.IPText>
              </Grid>
            </ConditionalFragment>
            <Grid container>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <Styles.PublicBottomMargin>
                  <Heading small name={'vs/instance.string31'} />
                </Styles.PublicBottomMargin>
              </Grid>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                <Styles.PublicMarginLeft>
                  <div className="mb-lg-0 mb-sm-3 mb-3 mt-1">
                    <EditLabel
                      labelText={data.label}
                      data={data}
                      managerVmRefetch={managerVmRefetch}
                      allTags={allTags}
                      managerVmIsFetching={managerVmIsFetching}
                      isTags={isTags}
                      type={type}
                      statusOfServer={statusOfServer}
                      openTag={openTag}
                      handleClose={handleClose}
                      handleOpen={handleOpen}
                      setting
                    />
                  </div>
                </Styles.PublicMarginLeft>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <Styles.PublicBottomMargin>
                  <Heading small name={'vs/instance.string122'} />
                </Styles.PublicBottomMargin>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <Styles.PublicMarginLeft>
                  {get(network, 'networks', []).map((network) => {
                    return network.eth0?.map((eth) => {
                      return eth.ipv4?.map((ip) => (
                        <Grid container key={ip.ip}>
                          {ip.type == 'primary' ? <Styles.IPText>{ip.ip}</Styles.IPText> : null}
                        </Grid>
                      ));
                    });
                  })}
                </Styles.PublicMarginLeft>
              </Grid>
            </Grid>
          </>
        )}
        <Grid container>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            {type === 'vs' ? (
              <Styles.PublicBottomMargin>
                <Heading small name={'vs/instance.string118'} />
              </Styles.PublicBottomMargin>
            ) : (
              <Heading small name={'vs/instance.string118'} />
            )}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <Styles.PublicMarginLeft>
              <Styles.IPText>{moment(data.date_deployment_ordered).format('lll')}</Styles.IPText>
            </Styles.PublicMarginLeft>
          </Grid>
        </Grid>
        {type == 'vs' && (
          <Grid container>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Styles.PublicBottomMargin>
                <Heading small name={'vs/instance.string180'} />
              </Styles.PublicBottomMargin>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Styles.PublicMarginLeft>
                <Styles.IPText style={{ textTransform: 'capitalize' }}>{data?.billing_schedule}</Styles.IPText>
              </Styles.PublicMarginLeft>
            </Grid>
          </Grid>
        )}
        {type == 'vs' && data?.billing_schedule != 'hourly' && (
          <Grid container>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Styles.PublicBottomMargin>
                <Heading small name={'vs/instance.string196'} />
              </Styles.PublicBottomMargin>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Styles.PublicMarginLeft>
                <Styles.IPText style={{ textTransform: 'capitalize' }}>
                  {moment(data.next_payment_date).format('ll')}
                </Styles.IPText>
              </Styles.PublicMarginLeft>
            </Grid>
          </Grid>
        )}

        {type === 'bm' && (
          <Grid container>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Styles.PublicBottomMargin>
                <Heading small name={'vs/instance.string119'} />
              </Styles.PublicBottomMargin>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Styles.PublicMarginLeft>
                <Styles.IPText style={{ textTransform: 'capitalize' }}>
                  {moment(data.next_payment_date).format('lll')}
                </Styles.IPText>
              </Styles.PublicMarginLeft>
            </Grid>
          </Grid>
        )}

        <Grid container>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <Styles.PublicBottomMargin>
              <Heading small name={'vs/instance.string120'} />
            </Styles.PublicBottomMargin>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <Styles.PublicMarginLeft>
              <Styles.IPText style={{ textTransform: 'capitalize' }}>
                {`${data.os_name || t('bm/instance.string41')}${data.os_subname ? `, ${data.os_subname}` : ''}`}
              </Styles.IPText>
            </Styles.PublicMarginLeft>
          </Grid>
        </Grid>
        {type === 'bm' && (
          <>
            <Grid container>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <Styles.PublicBottomMargin>
                  <Heading small name={'bm/instance.string39'} />
                </Styles.PublicBottomMargin>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <Styles.PublicMarginLeft>
                  <Styles.IPText>{data?.sn || t('bm/instance.string41')}</Styles.IPText>
                </Styles.PublicMarginLeft>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <Styles.PublicBottomMargin>
                  <Heading small name={'bm/instance.string40'} />
                </Styles.PublicBottomMargin>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <Styles.PublicMarginLeft>
                  <Styles.IPText>{data?.size || t('bm/instance.string41')}</Styles.IPText>
                </Styles.PublicMarginLeft>
              </Grid>
            </Grid>
            <ConditionalFragment condition={serverSizeData && Object.keys(serverSizeData).length}>
              {serverSizeData && Object.keys(serverSizeData).length && (
                <Grid container>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12} />
                  <Styles.IPText style={{ margin: '-0.5rem 0 -0.5rem 1.8em' }}>
                    {features.map((feature) => (
                      <Feature style={{ alignItems: 'center' }} key={feature.id}>
                        <Icon size="tiny" name="circle" />
                        <Trans
                          i18nKey={
                            feature.dataKey2 == 'cpu_sockets'
                              ? serverSizeData[feature.dataKey2] == 1
                                ? feature.langSingle
                                : serverSizeData[feature.dataKey2] == 2
                                ? feature.langDual
                                : serverSizeData[feature.dataKey2] == 4
                                ? feature.langQuad
                                : null
                              : serverSizeData[feature.dataKey] > 1
                              ? feature.plural_lang
                              : feature.lang
                          }
                          values={{
                            count: serverSizeData[feature.dataKey],
                            counts: filterDataKey2(serverSizeData[feature.dataKey2], feature.lanRef),
                            count2: filterCount2(serverSizeData[feature.dataKey3], feature.lanRef),
                            count3: filterMemoryType(serverSizeData[feature.dataKey2], feature.lanRef),
                          }}
                        />
                      </Feature>
                    ))}
                    {serverSizeData.extra_hardware_megaraid == 0 && serverSizeData.extra_hardware == 1 ? (
                      <div>
                        <br />
                        <Extra>{t('bm/instance.string54')}</Extra>
                        {extraFeatures.map((feature) => (
                          <Feature style={{ alignItems: 'center' }} key={feature.id}>
                            <Icon size="tiny" name="circle" />
                            <Trans
                              i18nKey={feature.lang}
                              values={{
                                count: serverSizeData[feature.dataKey],
                                counts: filterDataKey2(serverSizeData[feature.dataKey2], feature.lanRef),
                                count2: filterCount2(serverSizeData[feature.dataKey3], feature.lanRef),
                                count3: filterMemoryType(serverSizeData[feature.dataKey2], feature.lanRef),
                              }}
                            />
                          </Feature>
                        ))}
                      </div>
                    ) : serverSizeData.extra_hardware_megaraid == 1 && serverSizeData.extra_hardware == 1 ? (
                      <div>
                        <br />
                        <Extra>{t('bm/instance.string54')}</Extra>
                        {extraWithMegaraidFeatures.map((feature) => (
                          <Feature style={{ alignItems: 'center' }} key={feature.id}>
                            <Icon size="tiny" name="circle" />
                            {t(feature.lang)}
                          </Feature>
                        ))}
                      </div>
                    ) : null}
                  </Styles.IPText>
                </Grid>
              )}
            </ConditionalFragment>
            <ConditionalFragment condition={rights.bmprice}>
              <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Styles.PublicBottomMargin>
                    <Heading small name={'bm/instance.string42'} />
                  </Styles.PublicBottomMargin>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Styles.PublicMarginLeft>
                    <Styles.IPText>
                      {'$'}
                      {data.price_monthly_usd}
                    </Styles.IPText>
                  </Styles.PublicMarginLeft>
                </Grid>
              </Grid>
            </ConditionalFragment>
          </>
        )}
        <Grid container>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <Styles.PublicBottomMargin>
              <Heading small name={type == 'bm' ? 'bm/instance.string57' : 'vs/instance.string114'} />
            </Styles.PublicBottomMargin>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <Styles.PublicMarginLeft>
              <Styles.IPText>{data.id}</Styles.IPText>
            </Styles.PublicMarginLeft>
          </Grid>
        </Grid>
      </div>
      <div className="second">
        <ConditionalFragment condition={type === 'vs' && rights?.vsipadd && data?.billing_schedule == 'hourly'}>
          <Grid container>
            <Grid xs={12}>
              <Styles.PublicMargin>
                <Heading small name={'vs/instance.string123'} />
              </Styles.PublicMargin>
            </Grid>
            <Grid xs={12}>
              <Styles.PublicMarginLeft>
                <AddIP
                  type={type}
                  howManyIps={network.can_add_ipv4_ips}
                  network={network}
                  data={data}
                  ipRefetch={ipRefetch}
                  managerVmRefetch={managerVmRefetch} // REFETCH VS/BM PANEL PAGE.
                />
              </Styles.PublicMarginLeft>
            </Grid>
          </Grid>
        </ConditionalFragment>
        <Grid container>
          <Grid xs={12}>
            <Styles.PublicMargin>
              <Heading small name={type == 'bm' ? 'bm/instance.string58' : 'vs/instance.string115'} />
            </Styles.PublicMargin>
          </Grid>
          <Grid xs={12}>
            <Grid container>
              <Styles.PublicMarginLeft>
                {checkIpQuantity(0) ? (
                  <Styles.PublicIPPara className="mt-3">{t('bm/instance.string59')}</Styles.PublicIPPara>
                ) : (
                  <>
                    {get(network, 'networks', []).map((network) => {
                      return network.eth0?.map((eth) => {
                        return eth.ipv4?.map((ip, index) => (
                          <span
                            key={index}
                            style={{ ...checkDisabledItem(ip.ip) }}
                            className="d-flex align-items-center"
                          >
                            <IPContainer ip={ip.ip} ipData={ip}>
                              <ConditionalFragment condition={type === 'vs' && rights.vsipdelete}>
                                <CustomButton
                                  pointerEvents={checkDisabledItem(ip.ip)}
                                  title={'vs/instance.string116'}
                                  buttonStyles={{
                                    marginLeft: '5px',
                                    padding: '3px 15px',
                                    ...(checkIpQuantity(1) ? disableStyles : {}),
                                  }}
                                  onClick={() => handleDelete(ip.ip)}
                                  isLoading={deletedItem === ip.ip && deleteIpLoad}
                                />
                              </ConditionalFragment>
                            </IPContainer>
                          </span>
                        ));
                      });
                    })}
                  </>
                )}
              </Styles.PublicMarginLeft>
            </Grid>
            <Styles.PublicMarginLeft>
              <Styles.PublicIPPara>
                {t(type === 'vs' ? `vs/instance.string117` : 'bm/instance.string60')}
              </Styles.PublicIPPara>
            </Styles.PublicMarginLeft>
          </Grid>
        </Grid>
        {type === 'vs' && (
          <Grid container>
            <Grid xs={12}>
              <Styles.PublicMargin>
                <Heading small name={type == 'bm' ? 'bm/instance.string63' : 'vs/instance.string126'} />
              </Styles.PublicMargin>
            </Grid>
            <Grid xs={12}>
              <Grid xs={12}>
                <Styles.PublicMarginLeft>
                  {get(network, 'networks', []).map((network) => {
                    return network.eth1?.map((eth) => {
                      return eth.ipv4?.map((ip, index) => (
                        <Grid item xs={12} className="d-flex align-items-center w-100" key={index}>
                          <div className="d-flex align-items-center">
                            <IPContainer ip={ip.ip} text="vs/instance.string127" ipData={ip} />
                          </div>
                        </Grid>
                      ));
                    });
                  })}
                </Styles.PublicMarginLeft>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </Styles.InfoDetail>
  );
};

export const Info = React.memo(InfoManager);
