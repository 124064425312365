/* eslint-disable no-unused-vars */
import React from 'react';
import * as Styles from './NewBMStep1.style';
import ServerSizeCard from 'components/bm-deployment/server-size-card';
import { Icon } from 'semantic-ui-react';
import { navLinks } from 'utils/nav-links-util';
import { StepLabel, Highlight, Notification } from 'components/vm-deployment/components';
import { useTranslation, Trans } from 'react-i18next';
import ServerSideStaticCard from 'components/bm-deployment/server-size-card/ServerSideStaticCard';
import { totalPrice } from 'components/vm-deployment/util';
import { get } from 'lodash';
import classes from 'assets/css/fading-animation.module.css';
import { useSelector } from 'react-redux';
import Heading from 'components/Heading/Heading';
import FormikErrorMessage from 'components/formik-error-message';

const NewBMStep1 = ({ serverSizesData, onServerSizeSelection, formikProp, isShowPriceButton }) => {
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  const isSaleAvailable = serverSizesData?.bm_sizes?.some((data) => Number(data.price) - Number(data.sale_price));
  const { values } = formikProp;
  return (
    <Styles.NewBMStep1SC id="ST1">
      {/* <StepLabel stepCount={t('bm/instance/create.step1_string1')}>
        <Trans i18nKey="bm/instance/create.step1_string2" components={{ highlight: <Highlight /> }} />
      </StepLabel> */}
      <Heading name={'bm/instance/create.step1_string2'}>
        <Styles.Margin />
        <Styles.ServerSizeGrid>
          <ServerSideStaticCard />
          {serverSizesData?.bm_sizes.map((serverSizeData) => (
            <>
              {serverSizeData.active && (
                <ServerSizeCard
                  key={serverSizeData.size}
                  data={serverSizeData}
                  selected={serverSizeData?.size === values?.id}
                  onClick={onServerSizeSelection}
                  formikProp={formikProp || {}}
                  userAccount={userAccount}
                />
              )}
            </>
          ))}
        </Styles.ServerSizeGrid>

        {isSaleAvailable && (
          <>
            <Styles.NotificationContainer>
              <Notification>
                <Icon name="info circle" />
                {t('bm/instance/create.step1_string7')}
              </Notification>
            </Styles.NotificationContainer>
            <Styles.SaleNotification>
              <Notification>
                <Icon name="info circle" />
                {t('bm/instance/create.step1_string8')}
              </Notification>
            </Styles.SaleNotification>
          </>
        )}
        <div className={isShowPriceButton ? classes.price_box_show : classes.price_box_hide}>
          <Styles.Footer>
            <Styles.FooterText>
              <Trans
                i18nKey={'string1'}
                values={{
                  currency_symbol: userAccount.account_currency_symbol,
                  price: totalPrice(get(formikProp, 'values.prices', 0)),
                }}
              />
            </Styles.FooterText>
            <Styles.ButtonSC color="green" size="mini" href={navLinks.billing.link}>
              <Icon name="credit card" />
              {t('string2')}
            </Styles.ButtonSC>
          </Styles.Footer>
        </div>
      </Heading>
      <FormikErrorMessage value="size" formikProp={formikProp} />
    </Styles.NewBMStep1SC>
  );
};

export default NewBMStep1;
