import styled from 'styled-components';
import { variables } from 'variables';

export const NewBMStep1SC = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(props) => props.theme.pageBackgroundColor};
  padding: ${variables.pageContentPadding}px;
  padding-bottom: 0;
  padding-right: 0;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
`;

export const Margin = styled.div`
  margin-top: 5em;
`;
