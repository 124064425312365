import React, { useEffect } from 'react';
import { NewVMStep4SC, Padding, BackupOptions } from './NewVSStep4.style';
import ErrorMessage from 'components/error-message/ErrorMessage';
import { StepDescription } from 'assets/css/common-styles';
import Heading from 'components/Heading/Heading';
import { H6 } from 'components/reusable-css';
import Checkbox from 'components/common/radio-button/RadioButton';
import { useTranslation } from 'react-i18next';

const backupData = [
  {
    id: 'plan2',
    text: 'step4_string2',
  },
  {
    id: 'plan0',
    text: 'step4_string3',
  },
];
const NewVSStep4 = ({ formikProp }) => {
  const { t } = useTranslation();
  const { touched, values, setFieldValue } = formikProp;
  const handleOption = (value, price) => {
    setFieldValue('backup', value);
    if (value != 'plan0') {
      setFieldValue('monthly_price.backup', price);
      setFieldValue('yearly_price.backup', values.selected_size?.price_backup_yearly_usd || 0);
      setFieldValue('biennial_price.backup', values.selected_size?.price_backup_biennial_usd || 0);
      setFieldValue('triennial_price.backup', values.selected_size?.price_backup_triennial_usd || 0);
    } else {
      setFieldValue('monthly_price.backup', 0);
      setFieldValue('yearly_price.backup', 0);
      setFieldValue('biennial_price.backup', 0);
      setFieldValue('triennial_price.backup', 0);
    }
  };

  useEffect(() => {
    if (values.backup != 'plan0') {
      setFieldValue('monthly_price.backup', values.selected_size?.price_backup_monthly_usd);
      setFieldValue('yearly_price.backup', values.selected_size?.price_backup_yearly_usd);
      setFieldValue('biennial_price.backup', values.selected_size?.price_backup_biennial_usd);
      setFieldValue('triennial_price.backup', values.selected_size?.price_backup_triennial_usd);
    }
  }, [values.selected_size]);

  let serverBackupYearlyPrice = values.selected_size?.price_backup_yearly_usd || 0;
  let serverBackupPrice = values.selected_size?.price_backup_monthly_usd || 0;

  return (
    <NewVMStep4SC id="ST4">
      <Heading name="public/checkout/vs.step4_string1">
        <StepDescription />
        <Padding>
          {backupData.map((data) => (
            <BackupOptions key={data.id} onClick={() => handleOption(data.id, serverBackupPrice)}>
              <div className="cards">
                <Checkbox
                  checkbox={values.backup}
                  setCheckedValue={(value) => handleOption(value, serverBackupPrice)}
                  data={data}
                />
                {values.selected_size?.price_monthly_usd == '' && data.id == 'plan2' ? (
                  <H6 className="m-0">
                    {t(`public/checkout/vs.step4_string4`, {
                      currency_symbol: '$',
                      yearly_price: serverBackupYearlyPrice,
                    })}
                  </H6>
                ) : (
                  <H6 className="m-0">
                    {t(`public/checkout/vs.${data.text}`, {
                      price: serverBackupPrice,
                      currency_symbol: '$',
                      yearly_price: serverBackupYearlyPrice,
                    })}
                  </H6>
                )}
              </div>
            </BackupOptions>
          ))}
        </Padding>
        {values.backup == '' && touched.backup && <ErrorMessage />}
      </Heading>
    </NewVMStep4SC>
  );
};
export default NewVSStep4;
