import React from 'react';
import * as Styles from './NewBMStep7.style';
import Heading from 'components/Heading/Heading';
import RegionStep from 'components/steps/region-step/RegionStep';
import FormikErrorMessage from 'components/formik-error-message';
import { scrollToSection } from 'utils';

const NewBMStep7 = ({ serverSizesData, regionsData, formikProp }) => {
  const handleScrolldown = (region) => {
    scrollToSection('ST1');
    formikProp.setFieldValue('region', region.id);
    formikProp.setFieldValue('prices.size', '0');
    formikProp.setFieldValue('size', '');
    formikProp.setFieldValue('id', '');
  };
  return (
    <Styles.NewBMStep1SC id="ST0">
      <Heading name={'bm/instance/create.step0_string1'}>
        <Styles.Margin />
        <RegionStep
          handleScrolldown={handleScrolldown}
          regionsData={regionsData?.bm_regions}
          formikProp={formikProp}
          sizeData={serverSizesData?.bm_sizes}
        />
      </Heading>
      <FormikErrorMessage value="region" formikProp={formikProp} />
    </Styles.NewBMStep1SC>
  );
};

export default NewBMStep7;
