import { getLocalStorageValues } from 'constants/local-storage';
import { VS_CART, REMOVE_VS_CART, CLEAR_ALL_VS_CART, UPDATE_VS_CART } from 'store/types';
let { vs_cart } = getLocalStorageValues();

const initialState = {
  vs_deploy: vs_cart,
};

export default function (state = initialState, action) {
  let removeFromCart = [];
  if (action.type == REMOVE_VS_CART) {
    removeFromCart = state.vs_deploy.filter((_, index) => index != action.payload);
    localStorage.setItem('vs_cart', JSON.stringify([...removeFromCart]));
  }
  switch (action.type) {
    case VS_CART:
      return {
        ...state,
        vs_deploy: [...state.vs_deploy, { ...action.payload.vs_cart }],
      };
    case REMOVE_VS_CART:
      return {
        ...state,
        vs_deploy: [...removeFromCart],
      };
    case CLEAR_ALL_VS_CART:
      return {
        ...state,
        vs_deploy: [],
      };
    case UPDATE_VS_CART:
      return {
        ...state,
        vs_deploy: state.vs_deploy.map((item, index) => {
          if (index == action.payload.index) {
            return { ...item, term: action.payload.term };
          }
          return item;
        }),
      };
    default:
      return state;
  }
}
