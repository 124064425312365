import React from 'react';
import { NewVMStep1SC } from './BmCartStep1.style';
import { StepDescription } from 'assets/css/common-styles';
import Heading from 'components/Heading/Heading';
import RegionStep from 'components/steps/region-step/RegionStep';

const BmCartStep1 = ({ regionsData, formikProp, sizeData }) => {
  const { setFieldValue } = formikProp;
  const handleScrolldown = (region) => {
    setFieldValue('region', region.id);
    setFieldValue('size', '');
    setFieldValue('monthly_price.size', 0);
    setFieldValue('yearly_price.size', 0);
    setFieldValue('selected_region', region);
    setFieldValue('selected_size', {});
  };
  return (
    <NewVMStep1SC id="ST1">
      <Heading name="public/checkout/bm.step1_string2">
        <StepDescription />
        <RegionStep
          handleScrolldown={handleScrolldown}
          regionsData={regionsData?.bm_regions || []}
          formikProp={formikProp}
          sizeData={sizeData?.bm_sizes || []}
        />
      </Heading>
    </NewVMStep1SC>
  );
};

export default BmCartStep1;
