import { pStyles } from 'components/reusable-css';
import styled from 'styled-components';

export const FlexColumn = styled.div`
  display: flex;
  flex-flow: column wrap;
  .network-border {
    padding-left: 7px;
    border-left: 1px solid #e9e9e9;
  }
`;

export const Network = styled.div`
  padding-left: 2.2em;
  cursor: pointer;
  padding-bottom: 10px;
  p {
    color: #585858;
    margin-bottom: 4px;
  }
`;

export const SlidingPanel = styled.div`
  background: ${(props) => props.theme.panelBackground};
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ActionDetail = styled.div`
  padding: 30px 0 45px 0;
  display: flex;
`;

export const Action1 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 10px 80px 5px;
  margin: 0 30px;
  border: 2px solid #e8e8e8;
  border-radius: 8px;
  background: white;
`;

export const Action2 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 32px 80px 20px;
  margin-right: 40px;
  border: 2px solid #e8e8e8;
  border-radius: 8px;
  background: white;
`;

export const ActionOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionText = styled.div`
  display: flex;
  padding: 0 2px 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  i {
    font-size: 10px;
    margin-top: 3.5px;
    color: ${(props) => (props.isShow ? '#FF7300' : 'black')};
  }
`;

export const ActionOption = styled.div`
  margin: 0 6px;
  color: ${(props) => (props.isShow ? '#FF7300' : 'black')};
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  white-space: nowrap !important;
`;

export const PAymentTermSC = styled.div`
  p {
    margin-left: 5px;
  }
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  h6 {
    margin: 0;
  }
  .section {
    @media (max-width: 1250px) {
      width: 100%;
      margin: 0.5em 0 1em 1.8em;
    }
  }
  .width {
    width: 20em !important;
    @media (max-width: 1250px) {
      width: 100%;
    }
  }
  .margin {
    margin-left: -4px;
    @media (max-width: 1250px) {
      width: 100%;
      margin: 0.5em 0 1em 1.5em;
    }
  }
  @media (max-width: 1250px) {
    flex-wrap: wrap !important;
  }
`;

export const FirstSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  h6 {
    margin: 0;
  }
  .width {
    width: 20em !important;
    @media (max-width: 1250px) {
      width: 100%;
    }
  }
  @media (max-width: 1120px) {
    align-items: flex-start;
  }
  @media (max-width: 1250px) {
    flex-wrap: wrap;
  }
`;

export const PriceTermSC = styled.div`
  width: 100%;
`;

export const PriceTerm = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.5em;
  cursor: pointer;
  h6 {
    margin: 0;
    font-weight: bold;
  }
`;
