import { pStyles } from 'components/reusable-css';
import styled from 'styled-components';

export const CreditCardSC = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => (props.background ? 'white' : props.theme.pageBackgroundColor)};
  width: 100%;
  padding: 3em 2em 4em 2em;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  @media only screen and (max-width: 768px) {
    padding: 1em 0.5em;
  }
`;

export const CreditCards = styled.div`
  padding-top: 1em;
`;

export const Paragraph = styled.div`
  padding: 1.5em 1em 0 3.8em;
  p {
    margin-bottom: 1.3rem;
    font-size: ${(props) => props.theme.default?.p};
    ${(props) => pStyles(props.theme.resolutions)};
  }
`;

export const NewCard = styled.div`
  padding: ${(props) => (props.storedCard ? '2em 0 0 2.6em' : '0 0 0 3.5em')};
  display: flex;
  p {
    margin-bottom: 1.3rem;
    font-size: ${(props) => props.theme.default?.p};
    ${(props) => pStyles(props.theme.resolutions)};
  }
  @media only screen and (max-width: 550px) {
    padding: ${(props) => (props.storedCard ? '1.6em' : '0 0 0 3.5em')};
  }
`;
export const NewCardForm = styled.div`
  padding: 1.5em 0 0 2.6em;
`;
export const NewCardButton = styled.div`
  margin-top: 3.5rem;
`;
export const NotificationContainer = styled.div`
  margin-left: 3.7rem;
  margin-bottom: 1.2rem;
  @media only screen and (max-width: 550px) {
    margin-left: 1.6em;
  }
`;
