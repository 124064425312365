import React from 'react';
import { Cards, Card, Heading } from './CPanelCard.style';
import Checkbox from 'components/common/radio-button';
import { useTranslation } from 'react-i18next';
import { Message } from 'components/alert/Message';

const CPanel = ({ appData, formikProp }) => {
  const { values, setFieldValue } = formikProp;
  const { t } = useTranslation();

  const handleCardClick = (cPanel) => {
    if (
      (Number(values.selected_size.memory_mb) < Number(appData?.minimum_ram) ||
        Number(values.selected_size.disk_mb) < Number(appData?.minimum_disk)) &&
      cPanel?.id != undefined
    ) {
      Message.error(t('alert26'), t('error'));
      return;
    }
    if (cPanel?.id) {
      setFieldValue('app', cPanel.id);
      setFieldValue('monthly_price.app', cPanel.price_monthly_usd);
      setFieldValue('yearly_price.app', cPanel.price_yearly_usd);
    } else {
      setFieldValue('app', '');
      setFieldValue('monthly_price.app', 0);
      setFieldValue('yearly_price.app', 0);
    }
  };

  return (
    <Cards>
      {appData?.pricing?.map((item) => (
        <Card
          isGrey={
            Number(values.selected_size.memory_mb) < Number(appData?.minimum_ram) ||
            Number(values.selected_size.disk_mb) < Number(appData?.minimum_disk)
          }
          onClick={() => handleCardClick(item)}
          key={item.id}
        >
          <div className="cards">
            <Checkbox data={item} checkbox={values.app} onClick={() => handleCardClick(item)} />
            <Heading>
              {t(item.parameter1 == 1 ? `public/checkout/vs.step3_string4` : `public/checkout/vs.step3_string5`, {
                price: item?.price_monthly_usd,
                currency: 'USD',
                currency_symbol: '$',
                account_number: item.parameter1,
              })}
            </Heading>
          </div>
        </Card>
      ))}

      <Card onClick={() => handleCardClick()}>
        <div className="cards">
          <Checkbox data={{ id: '' }} checkbox={values.app} onClick={() => handleCardClick()} />
          <Heading>{t(`public/checkout/vs.step3_string6`)}</Heading>
        </div>
      </Card>
    </Cards>
  );
};

export default CPanel;
