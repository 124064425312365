import React, { useEffect } from 'react';
import { Card, Heading } from './OperatingSystemCard.style';
import { useMediaQuery } from '@material-ui/core';
import Checkbox from 'components/common/radio-button';
import { getOSImage, osPricing } from '../../util';
import { Message } from 'components/alert/Message';
import { useTranslation } from 'react-i18next';
import { distributeColumns, sortAlphabetically } from 'utils/sorted-os';

const OperatingSystemCard = ({ osData, formikProp }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = formikProp;

  const handleClick = (value) => {
    if (values.app == '') {
      let isBig = value.min_ram_mb == '4096' && values?.selected_size.memory_gb < 4;
      if (isBig) {
        Message.error(t('alert24'), t('error'));
      }
      setFieldValue('image', isBig ? '' : value.id);
      setFieldValue('monthly_price.os', isBig ? '' : value.price_monthly_usd);
      setFieldValue('yearly_price.os', isBig ? '' : value.price_yearly_usd);
      setFieldValue('biennial_price.os', isBig ? '' : value.price_biennial_usd);
      setFieldValue('triennial_price.os', isBig ? '' : value.price_triennial_usd);
      setFieldValue('selected_image', isBig ? '' : value);
    }
  };

  useEffect(() => {
    if (values.app == '') {
      setFieldValue('image', '');
      setFieldValue('monthly_price.os', 0);
      setFieldValue('yearly_price.os', 0);
      setFieldValue('biennial_price.os', 0);
      setFieldValue('triennial_price.os', 0);
      setFieldValue('selected_image', {});
    }
  }, [values.size]);

  useEffect(() => {
    if (values.app != '') {
      setFieldValue('image', '');
      setFieldValue('monthly_price.os', 0);
      setFieldValue('yearly_price.os', 0);
      setFieldValue('biennial_price.os', 0);
      setFieldValue('triennial_price.os', 0);
      setFieldValue('selected_image', {});
    }
  }, [values.app]);

  const sm = useMediaQuery('(max-width:599px)');
  const md = useMediaQuery('(max-width:959px)');
  const lg = useMediaQuery('(max-width:1280px)');
  const xl = useMediaQuery('(max-width:1919px)');

  let styling = sm
    ? { width: '100%', number: 1 }
    : md
    ? { width: '50%', number: 2 }
    : lg || xl
    ? { width: '33%', number: 3 }
    : { width: '25%', number: 4 };

  const sortedItems = sortAlphabetically(osData?.vs_os);
  const columns = distributeColumns(sortedItems, styling.number);

  return (
    <div className="d-flex ">
      {columns.map((col, colIndex) => (
        <div className="d-flex flex-column" key={colIndex} style={{ width: styling.width }}>
          {col.map((operatingSystem) => (
            <Card
              key={operatingSystem.id}
              isGrey={values.app != '' || (operatingSystem.min_ram_mb == '4096' && values?.selected_size.memory_gb < 4)}
              onClick={() => handleClick(operatingSystem)}
            >
              <Checkbox
                checkbox={values.image}
                setCheckedValue={(value) => setFieldValue('image', value)}
                data={operatingSystem}
                onClick={() => handleClick(operatingSystem)}
              />
              <div>
                <img src={getOSImage(operatingSystem?.family)} width={25} height={25} alt="OS Image" />
              </div>
              <Heading>
                {operatingSystem?.name} {osPricing(operatingSystem)}
              </Heading>
            </Card>
          ))}
        </div>
      ))}
    </div>
  );
};

export default OperatingSystemCard;
