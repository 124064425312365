import styled, { css } from 'styled-components';
import { Button } from 'semantic-ui-react';
import { deviceBreakpoints, variables } from 'variables';

export const NewVMStep2SC = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: ${variables.pageContentPadding}px;
  width: 100%;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  padding-right: 0px;
  padding-top: 4em;
  padding-bottom: 0;
`;

export const ServerSizeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  padding-right: 14px;
  padding-bottom: 5em;
`;

export const NotificationContainer = styled.div`
  display: flex;
  margin: 50px 0;
  padding-right: 15px;
`;

export const Footer = styled.div`
  z-index: 10;
  display: none;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 35px;
  background: ${(props) => props.theme.appDrawer.mobile.backgroundColor};
  width: 100vw;
  padding: 0 5px;

  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    display: flex;
  }
`;

export const FooterText = styled.div`
  color: ${(props) => props.theme.gray.shadeF};
  margin-left: 2px;
`;

export const ButtonSC = styled(Button)`
  &&& {
    display: inline-block;
    padding: 6px 8px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  padding: 1em;
  padding-right: 2em;
  border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  border-radius: 8px;
  margin-right: 10px;
  margin-bottom: 10px;
  @media only screen and (max-width: 920px) {
    width: 48%;
  }
  @media only screen and (max-width: 580px) {
    width: 100%;
  }
  ${(props) =>
    props.selected &&
    css`
      background-color: ${(props) => props.theme.primaryDarkColor};
      border: 2px solid ${(props) => props.theme.primaryDarkColor};
      color: white;
    `};
`;

export const CardMobile = styled.div`
  cursor: pointer;
  color: black;
  h5 {
    white-space: nowrap;
    padding: 0 1em;
    border-right: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  }
  ${(props) =>
    props.selected &&
    css`
      color: ${(props) => props.theme.primaryDarkColor};
    `};
`;

export const LineMobile = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  ${(props) =>
    props.selected &&
    css`
      border-bottom: 2px solid ${(props) => props.theme.primaryDarkColor};
    `};
`;

export const Heading = styled.div`
  font-size: 18px;
  font-weight: bolder;
  padding-left: 5px;
`;

export const ServerOptions = styled.div`
  display: block;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    display: none;
  }
`;

export const ServerOptionsMobile = styled.div`
  display: none;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    display: block;
  }
`;

export const CardContainer = styled.div`
  margin-top: 1em;
  margin-bottom: 2em;
  display: flex;
  flex-wrap: wrap;
`;

export const CardContainerMobile = styled.div`
  margin-bottom: 2em;
  display: flex;
`;

export const StepHeading = styled.h2`
  font-size: 22px;
  margin: 0;
  font-weight: bold;
  margin-top: -10px;
  margin-bottom: 1em;
`;

export const Line = styled.div`
  border-top: 2px solid orange;
  margin-right: 18px;
  border-radius: 15px;
  padding-bottom: 2.5em;
  .line {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid orange;
    margin-top: -1.5em;
    margin-left: ${(props) =>
      props.categoryID == 'general'
        ? '7em'
        : props.categoryID == 'highfrequency'
        ? '25em'
        : props.categoryID == 'highmemory'
        ? '41em'
        : '54.5em'};
  }
  .doubleline {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid ${(props) => props.theme.pageBackgroundColor};
    margin-top: -1.1em;
    margin-left: ${(props) =>
      props.categoryID == 'general'
        ? '7em'
        : props.categoryID == 'highfrequency'
        ? '25em'
        : props.categoryID == 'highmemory'
        ? '41em'
        : '54.5em'};
  }

  @media only screen and (max-width: 920px) {
    display: none;
  }
`;
