import { BM_CART, REMOVE_BM_CART, CLEAR_ALL_BM_CART, UPDATE_BM_CART } from 'store/types';

export const addBM_CART = (payload) => (dispatch) => {
  dispatch({
    type: BM_CART,
    payload,
  });
};

export const removeBM_CART = (payload) => (dispatch) => {
  dispatch({
    type: REMOVE_BM_CART,
    payload,
  });
};

export const clearAllBM_CART = (payload) => (dispatch) => {
  dispatch({
    type: CLEAR_ALL_BM_CART,
    payload,
  });
};

export const updateBM_CART = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_BM_CART,
    payload,
  });
};
