import { Formik } from 'formik';
import React from 'react';
import Issues from './create';
import { PUT_TICKET, POST_TICKET, ADD_TICKET_REPLY } from '../queries';
import { useMutation } from 'react-query';
import { mutationProps } from 'utils/custom-hook';
import { useHistory } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { validatePin } from 'utils';

export default function IssuesContainer() {
  let history = useHistory();
  const { mutate: modifyTicket, isLoading: modifyTicketLoading } = useMutation(PUT_TICKET);
  const { mutate: createTicket, isLoading: createTicketLoading } = useMutation(POST_TICKET);
  const { mutate: addReply, isLoading: addReplyLoading } = useMutation(ADD_TICKET_REPLY);

  // const { mutationProps } = CustomHook();
  const getValue = (value) => {
    if (value?.getMarkdown) return value.getMarkdown();
    return value;
  };
  const handleSubmit = async (values, { resetForm }) => {
    const { pin } = values?.step2 || {};

    await createTicket(
      { action: 'new', dept: values.step1, pin: pin?.length === 4 ? pin : undefined },
      mutationProps(resetForm, {
        onSuccess: async (res) => {
          // eslint-disable-next-line no-unused-vars
          const { pin, message, secret, ...remaining } = values?.step2 || {};
          await addReply(
            {
              action: 'reply',
              ticket_id: res.ticket_id,
              message: Base64.encode(message),
              secret: Base64.encode(secret),
            },
            {
              async onSuccess() {
                const promises = [];
                for (const key in remaining) {
                  if (remaining[key] != '') {
                    const promise = await modifyTicket({
                      action: 'append',
                      key,
                      value: Base64.encode(getValue(remaining[key]).replace(/\\/g, '')),
                      ticket_id: res.ticket_id,
                    });
                    promises.push(promise);
                  }
                }
                await Promise.all(promises);
                history.replace('/tickets');
              },
            },
          );
        },
      }),
    );
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        step2: {
          pin: '',
        },
      }}
      validate={(values) => {
        const errors = { step2: {} };
        if (values.step2.pin) {
          if (values.step2.pin?.length < 4) {
            errors.step2.pin = 'Pin is too short';
            return errors;
          }
          const valid = validatePin(values.step2?.pin);
          if (valid) errors.step2.pin = valid;
          else delete errors.step2;
        }
        if (!values.step2.pin) {
          delete errors.step2;
        }
        return errors;
      }}
      enableReinitialize={false}
    >
      {(formikProps) => (
        <Issues
          newTicketLoading={createTicketLoading || modifyTicketLoading || addReplyLoading}
          formikProps={formikProps}
        />
      )}
    </Formik>
  );
}
