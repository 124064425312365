import React, { Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import AppRouter from 'pages/AppRouter';
import { HashRouter } from 'react-router-dom';
import persistentStore from 'store';
import Loading from 'components/common/loading/Loading';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { getLocalStorageValues } from 'constants/local-storage';
import { PersistGate } from 'redux-persist/integration/react';
import { defaultQueryFn, defaultMutationFn, reactQueryConfig } from 'constants/index';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n';
import theme from 'themes/light-theme';

// provide the default query function to your app with defaultOptions
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      ...reactQueryConfig,
    },
    mutations: {
      mutationFn: defaultMutationFn,
    },
  },
});
export default function App() {
  const { userName } = getLocalStorageValues();
  const [loading, setLoading] = useState(true);
  if (userName.token) axios.defaults.headers.common.Authorization = `bearer ${userName.token}`;
  useEffect(() => {
    // Fetch data from API and update the theme
    theme.fetchDataAndUpdateTheme().then(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Loading gap={50} />;
  }
  return (
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <Provider store={persistentStore().store}>
          <PersistGate loading={null} persistor={persistentStore().persistor}>
            <Suspense fallback={<Loading gap={50} />}>
              <HashRouter>
                <AppRouter queryClient={queryClient} />
              </HashRouter>
            </Suspense>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </I18nextProvider>
  );
}
