import WindowOSImage from 'assets/images/os_windows.svg';
import CentOSImage from 'assets/images/os_centos.svg';
import DebianOSImage from 'assets/images/os_debian.svg';
import FedoraOSImage from 'assets/images/os_fedora.svg';
import UbuntuOSImage from 'assets/images/os_ubuntu.svg';
import LinuxOSImage from 'assets/images/os_almalinux.svg';
import RockyOSLinux from 'assets/images/os_RockyLinux.svg';
import * as Yup from 'yup';
import { gbToTBs } from 'utils'; //divided by 1000(Peter said soo)

export const filterDataKey2 = (datakey2, lanRef) => {
  if (lanRef == 'ram') {
    return datakey2 ? datakey2?.toUpperCase() : '';
  }
  if (lanRef == 'disk') {
    if (datakey2 >= 1000) return gbToTBs(datakey2).toFixed(2);
  }
  if (lanRef == 'cpu_speed') {
    if (datakey2 == 1) return 'Single';
    if (datakey2 == 2) return 'Dual';
    if (datakey2 == 4) return 'Quad';
  }
  if (lanRef == 'extra_hardware') {
    if (datakey2 >= 1000) return gbToTBs(datakey2);
  }
  return datakey2;
};

export const filterMemoryType = (datakey2, lanRef) => {
  if (lanRef == 'disk') {
    if (datakey2 >= 1000) return 'TB';
    else {
      return 'GB';
    }
  }
  if (lanRef == 'extra_hardware') {
    if (datakey2 >= 1000) return 'TB';
    else {
      return 'GB';
    }
  }
  return datakey2;
};

export const filterCount2 = (dataKey3, lanRef) => {
  if (lanRef == 'disk') {
    if (dataKey3 === 'nvme') {
      return 'NVMe';
    }
    return dataKey3 ? dataKey3.toUpperCase() : '';
  }
  if (lanRef == 'extra_hardware') {
    return dataKey3 ? dataKey3.toUpperCase() : '';
  }
  return dataKey3;
};

export const createFeatures = [
  {
    id: 1,
    dataKey: 'vcpus',
    lanRef: 'cpu',
    lang: 'public/checkout/vs.step2_string17',
    lang_plural: 'public/checkout/vs.step2_string18',
  },
  {
    id: 2,
    dataKey: 'memory_gb',
    lanRef: 'ram',
    lang: 'public/checkout/vs.step2_string13',
    lang_plural: 'public/checkout/vs.step2_string14',
  },
  {
    id: 3,
    dataKey: 'disk_gb',
    lanRef: 'ssd',
    lang: 'public/checkout/vs.step2_string15',
    lang_plural: 'public/checkout/vs.step2_string16',
  },
  {
    id: 4,
    dataKey: 'datatransfer_gb',
    lanRef: 'data_transfer',
    lang: 'public/checkout/vs.step2_string19',
    lang_plural: 'public/checkout/vs.step2_string20',
  },
  {
    id: 5,
    dataKey: 'bandwidth_gbps',
    lanRef: 'bandwidth_gbps',
    lang: 'public/checkout/vs.step2_string25',
    lang_plural: 'public/checkout/vs.step2_string25',
  },
  {
    id: 6,
    dataKey: 'ipv4',
    lanRef: 'ipv4',
    lang: 'public/checkout/vs.step2_string26',
    lang_plural: 'public/checkout/vs.step2_string26',
  },
  {
    id: 7,
    dataKey: 'private_vlan',
    lanRef: 'private_vlan',
    lang: 'public/checkout/vs.step1_string29',
    lang_plural: 'public/checkout/vs.step1_string29',
  },
  {
    id: 8,
    dataKey: 'ddos_protection',
    lanRef: 'ddos_protection',
    lang: 'public/checkout/vs.step2_string27',
    lang_plural: 'public/checkout/vs.step2_string27',
  },
];

export const initialValues = (regionsData = []) => {
  let bm_cart_selected_region = regionsData.find((data) => data.default == 1);
  return {
    region: bm_cart_selected_region?.id || '',
    size: '',
    app: '',
    image: '',
    selected_region: bm_cart_selected_region || {},
    selected_size: {},
    selected_image: {},
    monthly_price: {
      size: 0,
      os: 0,
      app: 0,
    },
    yearly_price: {
      size: 0,
      os: 0,
      app: 0,
    },
    biennial_price: {
      size: 0,
      os: 0,
      app: 0,
    },
    triennial_price: {
      size: 0,
      os: 0,
      app: 0,
    },
    term: 1,
    backup: 'plan0',
  };
};

export const validationSchema = Yup.object({
  region: Yup.string().required(),
  size: Yup.string().required(),
  app: Yup.string(),
  image: Yup.string(),
  backup: Yup.string().required(),
});

export const initialValuesLogin = {
  emailAddress: '',
  selected: 100,
  password: '',
};

export const validationSchemaLogin = Yup.object({
  emailAddress: Yup.string().email('Invalid email address'),
});

export const getOSImage = (name) => {
  switch (name) {
    case 'windows':
      return WindowOSImage;
    case 'centos':
      return CentOSImage;
    case 'debian':
      return DebianOSImage;
    case 'fedora':
      return FedoraOSImage;
    case 'ubuntu':
      return UbuntuOSImage;
    case 'almalinux':
      return LinuxOSImage;
    case 'rockylinux':
      return RockyOSLinux;
    default:
      return '';
  }
};

export const osPricing = (price) =>
  Number(price?.price_monthly_usd) > 0 ? `($${Number(price?.price_monthly_usd)}/mo)` : null;
