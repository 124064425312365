/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-27 18:15:40
 * @modify date 2021-01-27 18:15:41
 */

import { combineReducers } from 'redux';

import commonReducer from 'store/reducers/common-reducer';
import language from 'store/reducers/lang';
import vmDeployment from 'store/reducers/vm-deployment';
import bmDeployment from 'store/reducers/bm-deployment';
import user from 'store/reducers/user-details';
import vsCart from 'store/reducers/vs-shopping-cart';
import domainCart from 'store/reducers/domain-cart';
import bmCart from 'store/reducers/bm-shopping-cart';

export default combineReducers({
  commonStates: commonReducer,
  language,
  vmDeployment,
  user,
  bmDeployment,
  vsCart,
  domainCart,
  bmCart,
});
