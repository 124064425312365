import { passwordRegex, vsLabelRegex } from 'constants/regex';
import * as Yup from 'yup';
import { getCurrentYear } from 'utils/current-year';
import { useTranslation } from 'react-i18next';

export const initialValues = (userUI, regionsData = []) => {
  const { t } = useTranslation();
  let bm_selected_region = regionsData?.find((data) => data.default == 1);
  return {
    region: bm_selected_region?.id || '',
    payment_method: '',
    size: '',
    app: '',
    image: '',
    options_prepaid: '',
    root_pass: '',
    confirm_pass: '',
    managed_plan: '',
    virtual_server_name: t(userUI.bm_default_label) || 'My SSD Dedicated Server',
    ipv4_ips: 1,
    country: 'US',
    card_month: 1,
    card_year: getCurrentYear(),
    // bank_name: '',
    // bank_phone_number: '',
    prices: {
      image: '0',
      size: '0',
      app: '0',
      options_prepaid: 0,
    },
  };
};

export const validationSchema = (schema = {}) =>
  Yup.object({
    region: Yup.string().required('bm/instance/create.string8'),
    virtual_server_name: Yup.string()
      .max(32, 'bm/instance/create.string4')
      .matches(vsLabelRegex, 'bm/instance/create.string5')
      .required('bm/instance/create.string6'),
    id: Yup.string().required('bm/instance/create.string7'),
    image: Yup.string().required('bm/instance/create.string8'),
    options_prepaid: Yup.object({
      id: Yup.string().required('bm/instance/create.string7'),
    }).required('bm/instance/create.string10'),
    root_pass: Yup.string()
      .max(64, 'bm/instance/create.string11')
      .required('bm/instance/create.string12')
      .matches(passwordRegex, 'bm/instance/create.string13'),
    confirm_pass: Yup.string()
      .max(64, 'bm/instance/create.string11')
      .oneOf([Yup.ref('root_pass'), null], 'bm/instance/create.string9')
      .required('bm/instance/create.string12')
      .matches(passwordRegex, 'bm/instance/create.string13'),
    managed_plan: Yup.string().required('bm/instance/create.string8'),
    ...schema,
  });
