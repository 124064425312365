import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './styles';
import { useSelector } from 'react-redux';
import currencyFilter from 'utils/currency-filter';
import ConditionalFragment from 'components/conditional-fragment';
import { Trans, useTranslation } from 'react-i18next';
import VmPaymentForm from '../../new-vm/new-vm-step-7/vm-payment-form';
import { Formik } from 'formik';
import { initialValues, validate, cardError } from '../constants';
import Checkbox from 'components/common/radio-button';
import { useMutation, useQuery } from 'react-query';
import { Message } from 'components/alert/Message';
import { CHARGE_SAVED_CARD, SAVE_CHARGE_CARD } from '../queries';
import { vm_year } from 'utils';
import { CustomHook } from 'utils/custom-hook';
import { validationObject } from 'validations';
import { Link } from 'react-router-dom';
import { isChargeCard } from '../utils';
import { H6, P } from 'components/reusable-css';
import { userRights } from 'store/selectors/user-details';
import { UpperCaseWord } from 'assets/css/common-styles';
import HeadingWithIcon from 'components/Heading/Heading';
import { BILLING_CHARGE_PRODUCT } from 'components/vm-deployment/queries/billing';
import SlidingPanels from 'components/common/sliding-panel/SlidingPanel';
import { useMediaQuery } from '@material-ui/core';
import SlidingPanelPaymentTerm from 'components/billing/SlidingPanelPaymentTerm';
import { Icon } from 'semantic-ui-react';

const ServicesByServerpoint = ({ modalActive, handleCloseModal, refetchStoredCreditCards }) => {
  const match = useMediaQuery('(max-width:550px)');
  const [isOpen, setIsOpen] = useState(false);
  const rights = useSelector(userRights);
  const userAccount = useSelector((state) => state.user.account);
  const { t } = useTranslation();
  const state = useSelector((state) => state.user);
  const { account_approved } = state.account || {};
  const { mutationProps } = CustomHook();
  const {
    billing_due_bm_services,
    billing_bm_how_much_due,
    billing_bm_monthly_cost,
    billing_due_vps_services,
    billing_vps_how_many_due,
    billing_vps_active,
    billing_vps_monthly_cost,
    billing_vps_how_much_due,
    billing_vps_yearly_cost,
  } = state?.billing_info || {};
  const [isChecked, setIsChecked] = useState(billing_due_bm_services === '1' ? 'bm_due_amount' : 'bm_funds_monthly');
  const [isCheckedVs, setIsCheckedVs] = useState(
    billing_due_vps_services == 1 ? 'billing_vps_how_much_due' : 'billing_vps_monthly_cost',
  );
  const { mutate: chargeSavedCard, isLoading: chargeSavedCardLoading } = useMutation(CHARGE_SAVED_CARD);
  const { mutate: saveChargeCard, isLoading: saveChargeCardLoading } = useMutation(SAVE_CHARGE_CARD);
  const credit_card_info = useSelector((state) => state.user.billing_info);
  const billingRefetch = useSelector((state) => state.user.userBillingAccountRefetch);
  const { mutate: billingChargeProdut, isLoading: billingChargeProductLoad } = useMutation(BILLING_CHARGE_PRODUCT);
  const { data: serverData, isLoading } = useQuery('/vs/instance');

  const handlePayPalBilling = async () => {
    let body = {
      payment_method: 'paypal',
      product_class: 'ccloudres',
      product_items: 'all',
      currency: userAccount?.account_currency,
      term:
        billing_due_vps_services == 1
          ? 'due'
          : isCheckedVs == 'billing_vps_how_much_due'
          ? 'due'
          : isCheckedVs == 'billing_vps_monthly_cost'
          ? '1'
          : '12',
    };
    await billingChargeProdut(
      body,
      mutationProps(false, {
        isSuccess: false,
        onSuccess: (res) => {
          window.open(res?.payment_url, '_blank');
        },
      }),
    );
  };

  const handleOneTimeBilling = async (value) => {
    let body = {
      payment_method: value || 'onetime',
      product_class: 'ccloudres',
      product_items: 'all',
      currency: userAccount?.account_currency,
      term:
        billing_due_vps_services == 1
          ? 'due'
          : isCheckedVs == 'billing_vps_how_much_due'
          ? 'due'
          : isCheckedVs == 'billing_vps_monthly_cost'
          ? '1'
          : '12',
    };
    await billingChargeProdut(
      body,
      mutationProps(false, {
        isSuccess: value ? true : false,
        onSuccess: (res) => {
          if (res?.payment_url) {
            window.open(res?.payment_url, '_blank');
          }
        },
      }),
    );
  };

  const handleBmPayment = async (values, resetForm) => {
    const isChargeCardExist =
      (values.bm_price !== '0' || values.bm_price !== '') &&
      values.payment_method === 'credit_debit' &&
      isChargeCard(credit_card_info);
    const isNoChangeCard =
      (values.bm_price !== '0' || values.bm_price !== '') &&
      values.payment_method === 'credit_debit' &&
      !credit_card_info.billing_default_credit_card_exp &&
      !credit_card_info.billing_default_credit_card_last_four;

    if (isChargeCardExist) {
      try {
        await chargeSavedCard(
          {
            amount: values.bm_price,
            currency: userAccount?.account_currency,
          },
          mutationProps('', {
            onSuccess: async () => {
              await billingRefetch();
            },
          }),
        );
      } catch (err) {
        Message.error(t(`${err.response.data?.error}`));
      }
    }
    if (isNoChangeCard) {
      try {
        await saveChargeCard(
          {
            number: values.card_number,
            expmonth: ('0' + values.card_month).slice(-2),
            expyear: vm_year(values.card_year),
            cvv: values.cvv,
            name: values.card_name,
            phone: values.phone.replace(/\D/g, ''),
            organization: values.company,
            address1: values.billing_address,
            address2: values.address_continue,
            city: values.city,
            state: values.state,
            zipcode: values.zip,
            country: values.country,
            bank: values.bank_name,
            bankphone: values.bank_phone_number.replace(/\D/g, ''),
            currency: userAccount?.account_currency,
            amount_to_charge: '0',
            make_default: '1',
          },
          mutationProps(resetForm, {
            onSuccess: async () => {
              await refetchStoredCreditCards();
              await billingRefetch();
            },
          }),
        );
      } catch (err) {
        Message.error(t(`${err.response.data?.error}`));
      }
    }
  };

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const vsInstanceArr = serverData?.vs_instance?.filter(
    (val) => val?.billing_schedule != 'hourly' && val?.vs_cancelation_scheduled != 1,
  );

  return (
    <Styles.CloudSC background={state?.ui?.show_funds_deposit_section != 0}>
      <HeadingWithIcon name={'account/billing_info.string41'}>
        <P className="mt-3">{t('account/billing_info.string42')}</P>
        <div style={{ marginLeft: '-1.2em' }}>
          <Styles.CloudService>
            <HeadingWithIcon
              sub_heading
              name={
                state?.billing_info?.billing_due_vs_services == 1
                  ? 'account/billing_info.string11'
                  : 'account/billing_info.string10'
              }
            >
              <Styles.Paragraph>
                <ConditionalFragment condition={account_approved == 1}>
                  <P>
                    {Number(state?.billing_info?.billing_vs_active) > 0 ? (
                      <Trans
                        i18nKey={'account/billing_info.string12'}
                        components={{
                          span: <span style={{ fontWeight: 'bold' }} />,
                        }}
                        values={{
                          vs_count: state?.billing_info?.billing_vs_active,
                          monthly_price: currencyFilter(
                            state?.billing_info.billing_vs_monthly_cost,
                            state?.account?.account_currency,
                          ),
                          hourly_price: currencyFilter(
                            state?.billing_info?.billing_vs_hourly_cost,
                            state?.account?.account_currency,
                            '',
                            true,
                          ),
                        }}
                      />
                    ) : (
                      t('account/billing_info.string13')
                    )}
                  </P>
                </ConditionalFragment>

                {Number(state?.billing_info?.billing_vs_due_by_hours) > 0 ? (
                  <P style={{ color: 'red' }}>
                    <Trans
                      i18nKey={'account/billing_info.string16'}
                      components={{
                        bold_font: <span style={{ fontWeight: 'bold' }} />,
                        black_font: <span style={{ color: 'black' }} />,
                      }}
                      values={{
                        hours: state?.billing_info?.billing_vs_due_by_hours,
                        price: currencyFilter(
                          `-${state?.billing_info?.billing_vs_due_negative_balance}`,
                          state?.account?.account_currency,
                        ),
                      }}
                    />
                  </P>
                ) : (
                  <>
                    {Number(state?.billing_info?.billing_vs_active) > 0 && (
                      <Styles.HighlightPara>
                        <Trans
                          i18nKey={'account/billing_info.string14'}
                          components={{
                            bold_font: <span style={{ fontWeight: 'bold' }} />,
                            black_font: <span style={{ color: 'black' }} />,
                          }}
                          values={{
                            days: state?.billing_info?.billing_vs_days_left,
                          }}
                        />
                      </Styles.HighlightPara>
                    )}
                  </>
                )}
                {Number(state?.billing_info?.billing_vs_due_full_balance) > 0 && (
                  <P>
                    <Trans
                      i18nKey={'account/billing_info.string17'}
                      components={{
                        bold_font: <span style={{ fontWeight: 'bold' }} />,
                      }}
                      values={{
                        price: currencyFilter(
                          state?.billing_info?.billing_vs_due_full_balance,
                          state?.account?.account_currency,
                        ),
                      }}
                    />
                  </P>
                )}
              </Styles.Paragraph>
            </HeadingWithIcon>
          </Styles.CloudService>
          {state?.ui?.show_billing_reserved_instance == 1 && (
            <Styles.VsService
              id="add_funds_vs_reserved"
              isDue={billing_due_vps_services == 1}
              isActive={Number(billing_vps_active) > 0}
            >
              {modalActive && (
                <div
                  style={{ position: 'relative', right: 20, zIndex: 1000, fontSize: 30, cursor: 'pointer' }}
                  onClick={() => handleCloseModal()}
                >
                  <Icon name="close" />
                </div>
              )}
              <Formik
                initialValues={{
                  ...initialValues,
                  bm_price: state?.billing_info[isCheckedVs],
                }}
                validationSchema={!isChargeCard(credit_card_info) ? validationObject : ''}
                validate={(values) => {
                  return validate(values, 'bm');
                }}
                onSubmit={(values, { resetForm }) => handleBmPayment(values, resetForm)}
              >
                {(formikProp) => (
                  <HeadingWithIcon
                    sub_heading
                    name={
                      billing_due_vps_services == 1 ? 'account/billing_info.string43' : 'account/billing_info.string44'
                    }
                  >
                    <div id="Add_funds_vps" className="second-para">
                      {billing_vps_active == 0 && (
                        <P>
                          <Trans
                            i18nKey={'account/billing_info.string52'}
                            values={{
                              currency: userAccount.account_currency,
                              currency_symbol: userAccount.account_currency_symbol,
                              vps_active: billing_vps_active,
                              price: billing_vps_monthly_cost,
                            }}
                            components={{
                              font_bold: <span style={{ fontWeight: 'bold' }} />,
                              uppercase: <UpperCaseWord />,
                            }}
                          />
                        </P>
                      )}
                      {billing_due_vps_services == 1 && (
                        <P className="red">
                          {t(
                            billing_vps_how_many_due == 1
                              ? 'account/billing_info.string81'
                              : 'account/billing_info.string45',
                            { how_many_due: billing_vps_how_many_due },
                          )}
                        </P>
                      )}
                      {billing_due_vps_services != 1 && (
                        <P>{t('account/billing_info.string86', { how_many_due: billing_vps_active })}</P>
                      )}
                    </div>
                    {Number(billing_vps_active) > 0 && (
                      <>
                        <div style={{ margin: '0 0 2em -1.5rem' }}>
                          {billing_due_vps_services == 1 && (
                            <div className="d-flex">
                              <Styles.Prices onClick={() => setIsCheckedVs('billing_vps_how_much_due')}>
                                <Checkbox
                                  checkbox={isCheckedVs}
                                  setCheckedValue={() => setIsCheckedVs('billing_vps_how_much_due')}
                                  data={{ id: 'billing_vps_how_much_due' }}
                                />
                                <H6>
                                  <Trans
                                    i18nKey={'account/billing_info.string47'}
                                    values={{
                                      currency: userAccount.account_currency,
                                      currency_symbol: userAccount.account_currency_symbol,
                                      price: billing_vps_how_much_due,
                                    }}
                                    components={{ uppercase: <UpperCaseWord /> }}
                                  />
                                </H6>
                              </Styles.Prices>
                            </div>
                          )}
                          {billing_due_vps_services != 1 && (
                            <>
                              <div className="d-flex">
                                <Styles.Prices onClick={() => setIsCheckedVs('billing_vps_monthly_cost')}>
                                  <Checkbox
                                    checkbox={isCheckedVs}
                                    setCheckedValue={() => setIsCheckedVs('billing_vps_monthly_cost')}
                                    data={{ id: 'billing_vps_monthly_cost' }}
                                  />
                                  <H6>
                                    <Trans
                                      i18nKey={'account/billing_info.string48'}
                                      values={{
                                        price: billing_vps_monthly_cost,
                                        currency: userAccount.account_currency,
                                        currency_symbol: userAccount.account_currency_symbol,
                                      }}
                                      components={{ uppercase: <UpperCaseWord /> }}
                                    />
                                  </H6>
                                </Styles.Prices>
                              </div>
                              <div className="d-flex">
                                <Styles.Prices onClick={() => setIsCheckedVs('billing_vps_yearly_cost')}>
                                  <Checkbox
                                    checkbox={isCheckedVs}
                                    setCheckedValue={() => setIsCheckedVs('billing_vps_yearly_cost')}
                                    data={{ id: 'billing_vps_yearly_cost' }}
                                  />
                                  <H6>
                                    <Trans
                                      i18nKey={'account/billing_info.string49'}
                                      values={{
                                        price: billing_vps_yearly_cost,
                                        currency: userAccount.account_currency,
                                        currency_symbol: userAccount.account_currency_symbol,
                                      }}
                                      components={{ uppercase: <UpperCaseWord /> }}
                                    />
                                  </H6>
                                </Styles.Prices>
                              </div>
                            </>
                          )}
                        </div>
                        {billing_due_vps_services != 1 && (
                          <Styles.LastPara>
                            <P>{t('account/billing_info.string50')}</P>
                            <P>
                              <Trans i18nKey={'account/billing_info.string51'}>
                                <span
                                  style={{ textDecoration: 'underline', cursor: 'pointer', color: '#007bff' }}
                                  onClick={() => setIsOpen(vsInstanceArr.length > 0 ? true : false)}
                                />
                              </Trans>
                            </P>
                          </Styles.LastPara>
                        )}
                        <SlidingPanels size={match ? 93 : 72} isOpen={isOpen} isClose={() => setIsOpen(!isOpen)}>
                          <SlidingPanelPaymentTerm
                            setIsClose={(e) => setIsOpen(e)}
                            isLoading={isLoading}
                            vsInstanceArr={vsInstanceArr}
                          />
                        </SlidingPanels>

                        <VmPaymentForm
                          id="Add_funds_vps"
                          handlePaypalBilling={handlePayPalBilling}
                          handleOneTimeBilling={handleOneTimeBilling}
                          billingChargeProductLoad={billingChargeProductLoad}
                          allButton
                          amount={formikProp.values?.bm_price || 0}
                          paraAddfunds
                          addFundsBmCheck
                          addFunds
                          formikProp={formikProp}
                          cardError={() => cardError(formikProp.errors, formikProp.values, 'bm', formikProp.touched)}
                          creditCardButtonLoading={chargeSavedCardLoading || saveChargeCardLoading}
                          creditCardButtonDisabled={false}
                          isButton
                        />
                      </>
                    )}
                  </HeadingWithIcon>
                )}
              </Formik>
            </Styles.VsService>
          )}
          <Formik
            initialValues={{
              ...initialValues,
              bm_price: billing_due_bm_services === '1' ? billing_bm_how_much_due : billing_bm_monthly_cost,
            }}
            validationSchema={!isChargeCard(credit_card_info) ? validationObject : ''}
            validate={(values) => {
              return validate(values, 'bm');
            }}
            onSubmit={(values, { resetForm }) => handleBmPayment(values, resetForm)}
          >
            {(formikProp) => (
              <Styles.BareService id="Add_funds_bm">
                <HeadingWithIcon
                  name={
                    billing_due_bm_services == 1 ? 'account/billing_info.string23' : 'account/billing_info.string22'
                  }
                  font_red
                  sub_heading
                >
                  <Styles.Para>
                    {billing_due_bm_services == 1 ? (
                      <P>
                        <Trans
                          i18nKey={'account/billing_info.string24'}
                          components={{
                            font_bold: <span style={{ fontWeight: 'bold' }} />,
                            font_red: <span style={{ color: 'red' }} />,
                          }}
                          values={{
                            bm_count: state?.billing_info?.billing_bm_how_many_due,
                            price: currencyFilter(
                              state?.billing_info?.billing_bm_how_much_due,
                              state?.account?.account_currency,
                            ),
                          }}
                        />
                      </P>
                    ) : (
                      <ConditionalFragment condition={account_approved == 1}>
                        {Number(state?.billing_info?.billing_bm_active) > 0 ? (
                          <P>
                            <Trans
                              i18nKey={'account/billing_info.string25'}
                              components={{
                                font_bold: <span style={{ fontWeight: 'bold' }} />,
                              }}
                              values={{
                                bm_count: state?.billing_info?.billing_bm_active,
                                price: currencyFilter(
                                  state?.billing_info?.billing_bm_monthly_cost,
                                  state?.account?.account_currency,
                                ),
                              }}
                            />
                          </P>
                        ) : (
                          <P>{t('account/billing_info.string39')}</P>
                        )}
                      </ConditionalFragment>
                    )}
                    <ConditionalFragment condition={account_approved == 1}>
                      {Number(state?.billing_info?.billing_bm_active) == 0 && (
                        <P>
                          <Trans i18nKey={'account/billing_info.string26'}>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href="https://ServerPoint.com/en/bare-metal-dedicated-server/"
                            />
                          </Trans>
                        </P>
                      )}
                    </ConditionalFragment>
                    <ConditionalFragment condition={account_approved == 0}>
                      <P>
                        <Trans
                          i18nKey={'account/billing_info.string28'}
                          components={{
                            bm_link: <Link to={'/bm/instance/create'} />,
                          }}
                        />
                      </P>
                    </ConditionalFragment>
                    <br />
                    <ConditionalFragment
                      condition={
                        Number(state?.billing_info?.billing_bm_how_much_due) > 0 ? true : rights.billingmakepmt !== 0
                      }
                    >
                      <div style={{ marginLeft: '-1.5rem' }}>
                        {state?.billing_info?.billing_due_bm_services == 1 ? (
                          <Styles.Price
                            onClick={() => {
                              setIsChecked('bm_due_amount');
                              formikProp.setFieldValue('bm_price', state?.billing_info?.billing_bm_how_much_due);
                            }}
                          >
                            <Checkbox
                              checkbox={isChecked}
                              setCheckedValue={() => {
                                setIsChecked('bm_due_amount');
                                formikProp.setFieldValue('bm_price', state?.billing_info?.billing_bm_how_much_due);
                              }}
                              data={{
                                id: 'bm_due_amount',
                              }}
                            />
                            <H6>
                              <Trans
                                i18nKey={'account/billing_info.string18'}
                                values={{
                                  price: currencyFilter(
                                    state?.billing_info?.billing_bm_how_much_due,
                                    state?.account?.account_currency,
                                  ),
                                }}
                              />
                            </H6>
                          </Styles.Price>
                        ) : (
                          <>
                            {Number(state?.billing_info?.billing_bm_active) > 0 && (
                              <Styles.Price
                                onClick={() => {
                                  setIsChecked('bm_funds_monthly');
                                  formikProp.setFieldValue('bm_price', state?.billing_info?.billing_bm_monthly_cost);
                                }}
                              >
                                <Checkbox
                                  checkbox={isChecked}
                                  setCheckedValue={() => {
                                    setIsChecked('bm_funds_monthly');
                                    formikProp.setFieldValue('bm_price', state?.billing_info?.billing_bm_monthly_cost);
                                  }}
                                  data={{
                                    id: 'bm_funds_monthly',
                                  }}
                                />
                                <H6>
                                  <Trans
                                    i18nKey={'account/billing_info.string29'}
                                    values={{
                                      price: currencyFilter(
                                        state?.billing_info?.billing_bm_monthly_cost,
                                        state?.account?.account_currency,
                                      ),
                                    }}
                                  />
                                </H6>
                              </Styles.Price>
                            )}
                          </>
                        )}
                      </div>
                      <br />
                      {Number(state?.billing_info?.billing_bm_active) > 0 && (
                        <VmPaymentForm
                          amount={formikProp.values?.bm_price || 0}
                          paraAddfunds
                          addFundsBmCheck
                          addFunds
                          formikProp={formikProp}
                          cardError={() => cardError(formikProp.errors, formikProp.values, 'bm')}
                          creditCardButtonLoading={chargeSavedCardLoading || saveChargeCardLoading}
                          creditCardButtonDisabled={false}
                          isButton
                        />
                      )}
                    </ConditionalFragment>
                  </Styles.Para>
                </HeadingWithIcon>
              </Styles.BareService>
            )}
          </Formik>
        </div>
      </HeadingWithIcon>
    </Styles.CloudSC>
  );
};
export default ServicesByServerpoint;
