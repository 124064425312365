import { getLocalStorageValues } from 'constants/local-storage';
import { BM_CART, REMOVE_BM_CART, CLEAR_ALL_BM_CART, UPDATE_BM_CART } from 'store/types';
let { bm_cart } = getLocalStorageValues();

const initialState = {
  bm_deploy: bm_cart,
};

export default function (state = initialState, action) {
  let removeFromCart = [];
  if (action.type == REMOVE_BM_CART) {
    removeFromCart = state.bm_deploy.filter((_, index) => index != action.payload);
    localStorage.setItem('bm_cart', JSON.stringify([...removeFromCart]));
  }
  switch (action.type) {
    case BM_CART:
      return {
        ...state,
        bm_deploy: [...state.bm_deploy, { ...action.payload.bm_cart }],
      };
    case REMOVE_BM_CART:
      return {
        ...state,
        bm_deploy: [...removeFromCart],
      };
    case CLEAR_ALL_BM_CART:
      return {
        ...state,
        bm_deploy: [],
      };
    case UPDATE_BM_CART:
      return {
        ...state,
        bm_deploy: state.bm_deploy.map((item, index) => {
          if (index == action.payload.index) {
            return { ...item, term: action.payload.term };
          }
          return item;
        }),
      };
    default:
      return state;
  }
}
