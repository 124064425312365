import React from 'react';
import { NewVMStep4SC, Padding } from './BmCartStep4.style';
import ErrorMessage from 'components/error-message/ErrorMessage';
import { StepDescription } from 'assets/css/common-styles';
import Heading from 'components/Heading/Heading';
import OperatingSystemCard from 'pages/shoping-cart/component/operating-system-card/OperatingSystemCard';

const BmCartStep4 = ({ osData, formikProp }) => {
  const { touched, values } = formikProp;

  return (
    <NewVMStep4SC id="ST4">
      <Heading name="public/checkout/bm.step4_string1">
        <StepDescription />
        <Padding>
          <OperatingSystemCard osData={osData?.bm_os || []} formikProp={formikProp} />
        </Padding>
        {values.app == '' && values.image == '' && touched.image && <ErrorMessage />}
      </Heading>
    </NewVMStep4SC>
  );
};
export default BmCartStep4;
