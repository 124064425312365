import React, { useState } from 'react';
import CustomButton from 'components/common/custom-button/custom-button';
import * as Styles from './IssueDetail.styles';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Hidden } from '@material-ui/core';
import { Base64 } from 'js-base64';
import { useMutation } from 'react-query';
import { CLOSE_TICKET } from '../queries';
import hooks from 'hooks';
import { useHistory } from 'react-router-dom';
import { H3, P, SPAN } from 'components/reusable-css';
import ReactMarkdown from 'markdown-to-jsx';
import { Trans, useTranslation } from 'react-i18next';
import { ChatReplyEditor } from '@sidraaabdullah/test-shared-library';

const SingleIssue = ({ data, handleAddReply, addReplyLoading }) => {
  const { t } = useTranslation();
  const ticket = data?.tickets[0];
  const [editorValue, setEditorValue] = useState('');
  const [secret, setSecret] = useState('');
  const { mutate: closeTicket, isLoading: closeTicketLoading } = useMutation(CLOSE_TICKET);
  const { mutationProps } = hooks();
  const history = useHistory();

  return (
    <Styles.AllIssueSC>
      <Styles.FirstRow>
        <CustomButton
          leftIcon={<Icon name="angle left" />}
          type="submit"
          title={t('tickets.string7')}
          onClick={() => history.push('/tickets')}
          buttonStyles={{ marginRight: '10px', paddingLeft: '5px' }}
        />
        <Link to="/tickets/create">
          <CustomButton type="submit" title={t('tickets.string5')} />
        </Link>
        {/* </div> */}
      </Styles.FirstRow>
      {ticket?.status == 0 ? (
        <Styles.TicketClosed>
          <Icon name="exclamation circle" />
          {t('tickets.string8')}
        </Styles.TicketClosed>
      ) : null}
      <Styles.Container>
        <Styles.Heading>{atob(ticket?.subject)}</Styles.Heading>
        <Styles.SecondRow>
          <Styles.Flex>
            <Styles.P>
              <Trans
                i18nKey={'tickets.string9'}
                values={{
                  ticket_id: ticket.id,
                  date: ticket.date_local_format_mdy,
                }}
              />
            </Styles.P>
            <Icon className="dot" name="circle" />
            <Styles.P>
              <Icon name="comment outline" />
              {ticket?.message_count}
            </Styles.P>
          </Styles.Flex>
        </Styles.SecondRow>
        <Styles.Chat>
          {ticket?.ticket_thread?.map((val, index) => (
            <>
              {val.from_client_or_staff != 'client' && (
                <Styles.ReplyContainer>
                  <Styles.ReplyChat>{t('tickets.string10')}</Styles.ReplyChat>
                  <Icon name="reply" size="large" />
                </Styles.ReplyContainer>
              )}
              <Hidden only={['xs', 'sm']}>
                <Styles.ChatMobile key={index}>
                  <Styles.Div1>
                    <Styles.DateSC bg={val.from_client_or_staff}>
                      <Styles.UserName staff={val.from_client_or_staff != 'client'}>
                        {val.from_client_or_staff != 'client' ? 'Staff' : 'You'}: <span>{val.username}</span>
                      </Styles.UserName>
                      <Styles.TimeAgo>{val.date_ago_en}</Styles.TimeAgo>
                    </Styles.DateSC>
                  </Styles.Div1>
                  <Styles.Line>
                    <i className="fa fa-circle line-background" />
                  </Styles.Line>
                  <Styles.Div3>
                    <Styles.DetailSC bg={val.from_client_or_staff} secret={val.secret}>
                      <Styles.MessageDate staff={val.from_client_or_staff != 'client'}>
                        <Trans
                          i18nKey={'tickets.string11'}
                          values={{
                            date: val.date_local_format_mdy,
                          }}
                        />
                      </Styles.MessageDate>
                      <ReactMarkdown className="line-break">{Base64.decode(val?.message)}</ReactMarkdown>
                    </Styles.DetailSC>
                    {val.secret && (
                      <Styles.SecretInfoContainer>
                        <div>
                          <p>
                            <strong>
                              {val.from_client_or_staff != 'client' ? t('tickets.string12') : t('tickets.string13')}
                            </strong>
                          </p>
                          <p>
                            <i>{Base64.decode(val.secret)}</i>{' '}
                          </p>
                        </div>
                      </Styles.SecretInfoContainer>
                    )}
                  </Styles.Div3>
                </Styles.ChatMobile>
              </Hidden>
              <Hidden only={['md', 'lg', 'xl']}>
                <Styles.ChatMobile key={index}>
                  <Styles.Line>
                    <i className="fa fa-circle line-background" />
                  </Styles.Line>
                  <Styles.Chatlog>
                    <Styles.Div1>
                      <Styles.DateSC bg={val.from_client_or_staff}>
                        <Styles.UserName staff={val.from_client_or_staff != 'client'}>
                          {val.from_client_or_staff != 'client' ? 'Staff' : 'You'}: <span>{val.username}</span>
                        </Styles.UserName>
                      </Styles.DateSC>
                    </Styles.Div1>
                    <Styles.Div3>
                      <Styles.DetailSC bg={val.from_client_or_staff} secret={val.secret}>
                        <Styles.MessageDate staff={val.from_client_or_staff != 'client'}>
                          <Trans
                            i18nKey={'tickets.string11'}
                            values={{
                              date: val.date_local_format_mdy,
                            }}
                          />
                        </Styles.MessageDate>
                        <ReactMarkdown className="line-break">{Base64.decode(val?.message)}</ReactMarkdown>
                      </Styles.DetailSC>
                      {val.secret && (
                        <Styles.SecretInfoContainer>
                          <div>
                            <p>
                              <strong>
                                {val.from_client_or_staff != 'client' ? t('tickets.string12') : t('tickets.string13')}
                              </strong>
                            </p>
                            <p>
                              <i>{Base64.decode(val.secret)}</i>{' '}
                            </p>
                          </div>
                        </Styles.SecretInfoContainer>
                      )}
                    </Styles.Div3>
                  </Styles.Chatlog>
                </Styles.ChatMobile>
              </Hidden>
            </>
          ))}
        </Styles.Chat>
        {ticket?.status != 0 ? (
          <>
            <Styles.PostTxt>
              <H3>{t('tickets.string14')}</H3>
            </Styles.PostTxt>
            <ChatReplyEditor
              secret={secret}
              tickets={{
                string15: t('tickets.string15'),
                string16: t('tickets.string16'),
                string17: t('tickets.string17'),
                string18: t('tickets.string18'),
              }}
              Span={SPAN}
              handleAddReply={async (value) => {
                await handleAddReply(value, secret, () => {
                  setSecret('');
                  setEditorValue('');
                });
              }}
              ticketReplyLoading={addReplyLoading}
              setSecret={setSecret}
              setEditorValue={setEditorValue}
              Paragraph={P}
              editorValue={editorValue}
            />
            <Styles.ReplyChatContainer>
              <CustomButton
                type="submit"
                title={t('tickets.string19')}
                buttonStyles={{
                  background: '#8552da',
                  marginTop: '3em',
                }}
                isLoading={closeTicketLoading}
                onClick={() => {
                  closeTicket(
                    {
                      ticket_id: ticket.id,
                      action: 'close',
                    },
                    mutationProps(() => {}, {
                      onSuccess: async () => {
                        history.push('/tickets');
                      },
                    }),
                  );
                }}
              />
            </Styles.ReplyChatContainer>
          </>
        ) : null}
      </Styles.Container>
    </Styles.AllIssueSC>
  );
};

export default SingleIssue;
