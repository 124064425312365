import React, { useRef, useState } from 'react';
import CustomButton from 'components/common/custom-button';
import Modals from 'components/modal';
import LogoImage from 'assets/images/serverpoint.svg';
import PinInput from 'react-pin-input';
import * as Styles from '../Checkout.style';
import { useMediaQuery } from '@material-ui/core';
import { TWOFALOG_IN } from 'components/vm-deployment/queries';
import { useQuery } from 'react-query';
import { reactQueryConfig } from 'constants/react-query-config';
import { P } from 'components/reusable-css';
import { Message } from 'components/alert/Message';
import axios from 'axios';
import { navLinks } from 'utils/nav-links-util';

const CartLoginWithPin = ({ handleClose, open, userName, password, backupNumber }) => {
  const pinCodeRef = useRef(null);
  const mobile = useMediaQuery('(max-width:500px)');
  const [code, setCode] = useState('');
  const [trigger, setTrigger] = useState(false);

  const { isLoading: loginIsLoading } = useQuery(
    ['LOG_IN', { userName, password: btoa(password), code }],
    TWOFALOG_IN,
    {
      enabled: trigger,
      ...reactQueryConfig,
      onSuccess: async (res) => {
        setTrigger(false);
        if (res?.status == 'pass_reset_required') {
          history.replace(navLinks['expired-password'].link);
        } else if (res.status == 'two_factor_sms_sent') {
          Message.success('A new code has been sent', 'Info');
          setCode('');
          pinCodeRef.current.clear();
          pinCodeRef.current.focus();
        } else {
          localStorage.setItem(
            'remember_bm_cart_login',
            JSON.stringify({ userName: res.username, email: userName, token: res.token }),
          );
          localStorage.setItem(
            'userName',
            JSON.stringify({
              userName: res.username,
              email: userName,
              token: res.token,
            }),
          );
          axios.defaults.headers.common.Authorization = `bearer ${res.token}`;
          handleClose();
        }
      },
      onError: () => {
        pinCodeRef.current.clear();
        setCode('');
        Message.error('Code mismatch! Please try again.', 'Error');
        setTrigger(false);
      },
    },
  );

  const handleSubmit = () => setTrigger(true);

  const handleLinkClick = () => {
    setCode('999999');
    setTrigger(true);
  };

  let disabled = code.split('').length != 6 || loginIsLoading || trigger;

  return (
    <Modals open={open} onClose={handleClose}>
      <Styles.SortBy>
        <Styles.SignInContainer>
          <Styles.Logo>
            <img src={LogoImage} alt="serverpoint" width="100%" />
          </Styles.Logo>
          <Styles.SignInBox>
            <Styles.PinSC>
              <PinInput
                ref={pinCodeRef}
                length={6}
                initialValue=""
                type="numeric"
                inputMode="number"
                style={{ padding: '10px 0px' }}
                inputStyle={{
                  width: mobile ? '38px' : '50px',
                  height: mobile ? '50px' : '70px',
                  border: '2px solid #b3b3b3b3',
                  borderBottom: '3px solid #FF7300',
                  background: 'white',
                  margin: mobile ? '0 5px' : '0 10px',
                  fontSize: mobile ? '22px' : '30px',
                }}
                onComplete={(value) => {
                  setCode(value);
                  handleSubmit();
                }}
                onChange={(value) => setCode(value)}
                autoSelect
                focus
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </Styles.PinSC>
            <Styles.Flex>
              <Styles.Row>
                <Styles.LinkSC to={'/'} margin="15px">
                  Try again
                </Styles.LinkSC>
              </Styles.Row>
              <Styles.LogInButton>
                <CustomButton
                  type="submit"
                  title="Log in"
                  disabled={disabled}
                  onClick={handleSubmit}
                  isLoading={loginIsLoading || trigger}
                  buttonStyles={{ width: '100%' }}
                />
              </Styles.LogInButton>
            </Styles.Flex>
            {backupNumber == 1 && (
              <Styles.Para>
                <P>
                  You have a backup phone number configured. To send an SMS code to that number,{' '}
                  <Styles.Linked loading={trigger} onClick={handleLinkClick}>
                    please click here
                  </Styles.Linked>
                  .
                </P>
              </Styles.Para>
            )}
          </Styles.SignInBox>
        </Styles.SignInContainer>
      </Styles.SortBy>
    </Modals>
  );
};
export { CartLoginWithPin };
