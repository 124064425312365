import React, { useState } from 'react';
import { NewDomainSC } from './NewDomain.style';
import { useMutation } from 'react-query';
import { DOMAIN_LOOKUP } from 'pages/member/wh/queries';
import { CustomHook } from 'utils/custom-hook';
import { Formik } from 'formik';
import { initialValues, validationSchema } from 'components/doamain/utils';
import DomainStep1 from './new-domain-step-1/DomainStep1';
import DomainStep2 from './new-domain-step-2/DomainStep2';
import DomainLastStep from './new-domain-last-step/DomainLastStep';
import { useDispatch, useSelector } from 'react-redux';
import { addDomain_CART } from 'store/actions/domain-cart';

const NewDomain = ({ topWarningHeight }) => {
  const dispatch = useDispatch();
  const { mutate, isLoading, isSuccess } = useMutation(DOMAIN_LOOKUP);
  const { mutationProps } = CustomHook();
  const [isDomainAvailable, setIsDomainAvailable] = useState('');
  const domainCart = useSelector((state) => state.domainCart.domain_deploy);

  const handleSubmit = (values, setSubmitting) => {
    let domain_cart = { ...values };
    dispatch(addDomain_CART({ domain_cart }));
    localStorage.setItem('domain_cart', JSON.stringify([...domainCart, { ...domain_cart }]));
    setTimeout(() => {
      setSubmitting(false);
    }, 0.5 * 1000);
  };

  const handleNewDomain = (values, setFieldValue) => {
    mutate(
      { domain: values.new_domain },
      mutationProps(() => {}, {
        isSuccess: false,
        onSuccess: async (e) => {
          if (e?.status == 'domain_not_available') {
            setFieldValue('price', 0);
            setIsDomainAvailable(e.status);
          } else if (e?.status == 'domain_available') {
            setIsDomainAvailable(e.status);
            setFieldValue('price', Number(e.price));
          }
        },
      }),
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
    >
      {(formikProp) => (
        <NewDomainSC topWarningHeight={topWarningHeight}>
          <div>
            <DomainStep1
              isLoading={isLoading}
              isSuccess={isSuccess}
              formikProp={formikProp}
              isDomainAvailable={isDomainAvailable}
              handleNewDomain={(values) => handleNewDomain(values, formikProp.setFieldValue)}
            />
            {isDomainAvailable == 'domain_available' && <DomainStep2 formikProp={formikProp} />}
          </div>

          {isDomainAvailable == 'domain_available' && <DomainLastStep formikProp={formikProp} />}
        </NewDomainSC>
      )}
    </Formik>
  );
};
export default NewDomain;
