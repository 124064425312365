import React from 'react';
import * as Styles from 'pages/member/manage-vm/ManageVM.style';
import hooks from 'hooks';
import { useTranslation } from 'react-i18next';

const isNewInstance = ({ completeTime, styleChange = false, vmCard = false }) => {
  const { isNewInstance } = hooks();
  const { t } = useTranslation();
  const isNew = (assignedTime) => isNewInstance(assignedTime);

  return (
    <div
      style={{
        marginTop: styleChange ? '-5px' : 0,
        marginLeft: vmCard ? '1rem' : 0,
        opacity: isNew(completeTime) ? 1 : 0,
        pointerEvents: isNew(completeTime) ? 'none' : 'fill',
      }}
      className="mr-1"
    >
      <Styles.NewTagButton>{t('new')}</Styles.NewTagButton>
    </div>
  );
};

export default isNewInstance;
