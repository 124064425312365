import React from 'react';
import BmCart from './BmCart';
import { initialValues, validationSchema } from '../util';
import { Formik } from 'formik';
import { addBM_CART } from 'store/actions/bm-shopping-cart';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import Loading from 'components/common/loading';
import { triYearlyTotalPrice, biYearlyTotalPrice, yearlyTotalPrice, totalPrice } from 'pages/checkout-member/vs/util';

const BmCartContainer = () => {
  const dispatch = useDispatch();
  const { data: regionsData, isLoading: regionLoading } = useQuery('/public/checkout/bm/regions');
  const cartBm = useSelector((state) => state.bmCart.bm_deploy);
  const handleSubmit = (values, setSubmitting) => {
    let price = totalPrice(values);
    let yearly_price = yearlyTotalPrice(values);
    let bi_yearly_price = biYearlyTotalPrice(values);
    let tri_yearly_price = triYearlyTotalPrice(values);
    let { selected_region, selected_size, app, selected_image, term, backup } = values;
    let bm_cart = {
      tri_yearly_price,
      bi_yearly_price,
      selected_region,
      selected_size,
      app,
      selected_image,
      price,
      yearly_price,
      term,
      backup,
    };
    dispatch(addBM_CART({ bm_cart }));
    localStorage.setItem('bm_cart', JSON.stringify([...cartBm, { ...bm_cart }]));
    setTimeout(() => {
      setSubmitting(false);
    }, 0.5 * 1000);
  };

  if (regionLoading) {
    return <Loading gap={200} />;
  }
  return (
    <Formik
      initialValues={initialValues(regionsData?.bm_regions)}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
    >
      {(formikProp) => <BmCart formikProp={formikProp} regionsData={regionsData} regionLoading={regionLoading} />}
    </Formik>
  );
};
export default BmCartContainer;
