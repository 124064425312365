import React from 'react';
import { NewVMStep5SC, AuthenticationSteps } from './BmCartStep5.style';
import FormikErrorMessage from 'components/formik-error-message';
import Heading from 'components/Heading/Heading';
import { Grid, useMediaQuery } from '@material-ui/core';
import { H5, P } from 'components/reusable-css';
import Select from 'components/select';
import classes from './label-field.module.css';
import { useTranslation } from 'react-i18next';
import Loading from 'components/common/loading';

const BmCartStep5 = ({ formikProp, bmUpgrade = [], bmUpgradeLoading }) => {
  const { t } = useTranslation();
  const matches = useMediaQuery('(max-width:450px)');
  const width = matches ? '100%' : '25rem';

  const selectStyling = {
    width,
    marginTop: '1.7rem',
  };

  const renderUpgradeOptions = (upgradeKey) => {
    const upgradeData = bmUpgrade?.[0]?.[upgradeKey];
    if (!upgradeData) return null;

    let select_array = upgradeData.items?.map((data) => ({
      value: data.id,
      text: data.api_language_string,
    }));

    select_array.unshift({
      value: '',
      text: 'Please select',
    });

    return (
      <Grid className={classes.main} container key={upgradeKey}>
        <Grid item xs={12} sm={5} md={4} lg={4} xl={2} className="pr-5">
          <H5 className={classes.label}>{t(`public/checkout/bm.${upgradeData.api_language_string}`)}</H5>
        </Grid>
        <Grid item xs={12} sm={7} lg={8} xl={10}>
          <Select styling={selectStyling} options={select_array} />
        </Grid>
      </Grid>
    );
  };

  const upgradeKeys = [
    'additionaldisk2',
    'additionaldisk3',
    'additionaldisk4',
    'additionaldisk5',
    'additionalipnumbers',
  ];

  return (
    <NewVMStep5SC>
      <Heading name={'public/checkout/bm.step5_string1'}>
        {bmUpgradeLoading ? (
          <Loading />
        ) : (
          <>
            <AuthenticationSteps>
              {bmUpgrade?.length > 0 ? (
                upgradeKeys.map(renderUpgradeOptions)
              ) : (
                <P>{t('public/checkout/bm.step5_string2')}</P>
              )}
            </AuthenticationSteps>
            <FormikErrorMessage
              isText
              textError="string9"
              formikProp={formikProp}
              value={['root_pass', 'confirm_pass', 'virtual_server_name']}
            />
          </>
        )}
      </Heading>
    </NewVMStep5SC>
  );
};

export default BmCartStep5;
