import React from 'react';
import * as Styles from './BmCartStep2.style';
import ErrorMessage from 'components/error-message';
import { StepDescription } from 'assets/css/common-styles';
import Heading from 'components/Heading/Heading';
import { H6, P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';
import ServerSizeCard from 'pages/shoping-cart/component/bm-server-size-card/ServerSizeCard';
import { Icon } from 'semantic-ui-react';

const BmCartStep2 = (props) => {
  const { serverSizes, formikProp } = props;
  const { errors, touched, values } = formikProp;
  const { t } = useTranslation();

  return (
    <Styles.NewVMStep2SC id="ST2">
      <Heading name="public/checkout/bm.step2_string1">
        <StepDescription />
        {serverSizes?.bm_sizes.length == 0 ? (
          <P style={{ marginBottom: '5em' }}>{t('public/checkout/bm.step2_string2')}</P>
        ) : (
          <>
            <div style={{ display: 'flex', marginBottom: '1em' }}>
              <Icon name="circle" className="circle" />
              <H6>{t('public/checkout/bm.step2_string3')}</H6>
            </div>
            <Styles.ServerSizeGrid>
              {serverSizes?.bm_sizes.map((serverSize, i) => {
                return (
                  serverSize.active &&
                  serverSize.cpugen == 'xeone5v4' &&
                  serverSize?.availableregions?.includes(values.region) && (
                    <ServerSizeCard
                      key={i}
                      data={serverSize}
                      selected={serverSize?.size === values?.size}
                      formikProp={formikProp || {}}
                    />
                  )
                );
              })}
            </Styles.ServerSizeGrid>
            <div style={{ display: 'flex', marginBottom: '1em' }}>
              <Icon name="circle" className="circle" />
              <H6>{t('public/checkout/bm.step2_string4')}</H6>
            </div>
            <Styles.ServerSizeGrid>
              {serverSizes?.bm_sizes.map((serverSize, i) => {
                return (
                  serverSize.active &&
                  serverSize.cpugen == 'xeongold' &&
                  serverSize?.availableregions?.includes(values.region) && (
                    <ServerSizeCard
                      key={i}
                      data={serverSize}
                      selected={serverSize?.size === values?.size}
                      formikProp={formikProp || {}}
                    />
                  )
                );
              })}
            </Styles.ServerSizeGrid>
          </>
        )}
        {errors.size && touched.size && <ErrorMessage />}
      </Heading>
    </Styles.NewVMStep2SC>
  );
};
export default BmCartStep2;
