import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

import { deviceBreakpoints, variables } from 'variables';

export const NewBMStep1SC = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(props) => props.theme.pageBackgroundColor};
  padding: ${variables.pageContentPadding}px;
  padding-top: 45px;
  padding-bottom: 0;
  padding-right: 0;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
`;

export const ServerSizeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
`;

export const Margin = styled.div`
  margin-top: 5em;
`;

export const NotificationContainer = styled.div`
  display: flex;
  margin: 50px 0 30px 0;
`;
export const SaleNotification = styled.div`
  display: flex;
  margin-bottom: 50px;
`;

export const Footer = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 35px;
  background: ${(props) => props.theme.appDrawer.mobile.backgroundColor};
  z-index: 100;
  width: 100vw;
  padding: 0 5px;

  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    display: flex;
  }
`;

export const FooterText = styled.div`
  color: ${(props) => props.theme.gray.shadeF};
  margin-left: 2px;
`;

export const ButtonSC = styled(Button)`
  &&& {
    display: inline-block;
    padding: 6px 8px;
  }
`;